import { StringToRoleMapper } from '@common/mappers/RoleMapper';
import { Role } from './Role';

export type AtriasFilterOption = { party: string };
export type SupplierFilterOption = { groupName: string; glns: string[] };
export type DgoFilterOption = { party: string; sources: string[] };

export interface FilterOptions {
  atriasFilters: AtriasFilterOption[];
  supplierFilters: SupplierFilterOption[];
  dgoFilters: DgoFilterOption[];
}

export class User {
  id: string;

  fullName: string;

  partyName: string;

  roles: Role[];

  atriasParties: string[];

  dgoParties: string[];

  sources: string[];

  glns: string[];

  filterOptions: FilterOptions;

  constructor(
    id: string,
    fullName: string,
    partyName: string,
    roles: string[],
    atriasParties: string[],
    dgoParties: string[],
    sources: string[],
    glns: string[],
    filterOptions: FilterOptions
  ) {
    this.id = id;
    this.fullName = fullName;
    this.partyName = partyName;
    this.roles = roles.map((role) => StringToRoleMapper(role));
    this.atriasParties = atriasParties;
    this.dgoParties = dgoParties;
    this.sources = sources;
    this.glns = glns;
    this.filterOptions = filterOptions;
  }

  /**
   * Checks if this user has any of the given roles.
   * @param roles roles to check for
   */
  isAuthorizedFor = (roles: Role[]): boolean => {
    return this.roles.some((userRole) => roles.indexOf(userRole) !== -1);
  };
}
