import React, { ReactElement } from 'react';
import { Drawer } from '@mui/material';

import SidebarContent from '@common/components/Layout/BaseLayout/SidebarContent';
import VersionBanner from '@common/components/Layout/BaseLayout/VersionBanner';
import styles from './style.module.scss';

const BaseLayout = (props: { children?: ReactElement }): ReactElement => {
  const showVersionBanner = window.runConfig.showVersionBanner === 'true';

  return (
    <>
      <Drawer
        className={
          showVersionBanner
            ? styles.navigationDrawerWithVersionBanner
            : styles.navigationDrawer
        }
        variant="persistent"
        anchor="left"
        PaperProps={{ elevation: 11 }}
        open>
        <SidebarContent />
      </Drawer>
      {showVersionBanner ? (
        <VersionBanner
          environment={window.runConfig.environment}
          version={window.runConfig.version}
        />
      ) : (
        <></>
      )}
      <main
        className={
          showVersionBanner
            ? styles.contentWithVersionBanner
            : styles.contentWithoutVersionBanner
        }>
        {props.children}
      </main>
    </>
  );
};

export default BaseLayout;
