import React, { ReactElement } from 'react';
import { Drawer, Grid } from '@mui/material';

import styles from './style.module.scss';

const VersionBanner = (props: {
  environment: string;
  version: string;
}): ReactElement => {
  return (
    <Drawer variant="persistent" anchor="top" open>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        wrap="nowrap"
        className={styles.banner}>
        <Grid item container direction="row" wrap="nowrap" spacing={2} xs={3}>
          <Grid item className={styles.environment}>
            <span>{props.environment}</span>
          </Grid>
          <Grid item className={styles.version}>
            {props.version}
          </Grid>
        </Grid>

        <Grid
          item
          container
          direction="row"
          wrap="nowrap"
          justifyContent="flex-end"
          spacing={2}>
          <Grid item>This is a test environment</Grid>
        </Grid>
      </Grid>
    </Drawer>
  );
};

export default VersionBanner;
