import { LoginStatus } from '@auth/models/LoginStatus';
import { Role } from '@auth/models/Role';
import { UserStatus } from '@auth/models/UserStatus';
import UserContext from '@auth/UserContext';
import React, { ReactElement, useContext } from 'react';

const RoleGuardedComponent = (props: {
  visibleForRoles?: Role[];
  children: ReactElement;
}): ReactElement => {
  const currentUserStatus: UserStatus = useContext(UserContext);

  if (!props.visibleForRoles?.length) {
    return props.children;
  }

  if (
    currentUserStatus.Status === LoginStatus.LoggedIn &&
    currentUserStatus.User.isAuthorizedFor(props.visibleForRoles)
  ) {
    return props.children;
  }

  return <></>;
};

export default RoleGuardedComponent;
