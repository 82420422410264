import React, { ReactElement } from 'react';
import { Grid } from '@mui/material';

import AtriasLogo from '@assets/images/atrias-logo.svg';
import styles from './style.module.scss';

const PageLoader = (): ReactElement => (
  <Grid
    className={styles.loadingContainer}
    container
    justifyContent="center"
    alignItems="center">
    <img className={styles.logoLoading} src={AtriasLogo} alt="Logo Atrias" />
  </Grid>
);

export default PageLoader;
