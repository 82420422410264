import React, { ReactElement } from 'react';
import { Grid } from '@mui/material';

import AtriasErrorImage from '@assets/images/atrias-error.svg';
import MainContentLayout from '@common/components/Layout/BaseLayout/MainContentLayout';
import styles from './style.module.scss';

const AtriasError = (props: {
  title?: string;
  text: string;
  showInsideLayout?: boolean;
  errorMessage?: string;
}): ReactElement => {
  const content = (
    <Grid
      container
      direction="column"
      className={
        props.showInsideLayout
          ? styles.errorContainerNoMargin
          : styles.errorContainer
      }
      alignItems="center">
      <Grid item className={styles.errorImage}>
        <img alt="Error" src={AtriasErrorImage} />
      </Grid>
      <Grid item className={styles.errorText}>
        <p>Something went wrong!</p>
      </Grid>
    </Grid>
  );

  return props.title && props.showInsideLayout ? (
    <MainContentLayout pageTitle={props.title}>{content}</MainContentLayout>
  ) : (
    content
  );
};

export default AtriasError;
