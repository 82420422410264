import React, { ReactElement } from 'react';
import { Helmet } from 'react-helmet-async';
import { Typography, Box } from '@mui/material';

import styles from './style.module.scss';

const MainContentLayout = (props: {
  pageTitle: string;
  pageSubtitle?: string;
  topRightElements?: ReactElement;
  otherHeaderElements?: ReactElement;
  children?: ReactElement;
  loading?: boolean;
  noBottomPadding?: boolean;
}): ReactElement => {
  return (
    <>
      <Helmet>
        <title>{props.pageTitle} | Atrias</title>
      </Helmet>
      <Box display="flex" flexDirection="column" height="100%" gap={2}>
        <Box className={styles.header} gap={2}>
          <Box display="flex" gap={1}>
            <Box maxWidth="25%">
              <Typography variant="h1">{props.pageTitle}</Typography>
              <Typography variant="body2" className={styles.pageSubtitle}>
                {props.pageSubtitle}
              </Typography>
            </Box>
            <Box flexGrow={1}>{props.topRightElements ?? <></>}</Box>
          </Box>
          <Box>{props.otherHeaderElements}</Box>
        </Box>
        <Box
          flexShrink={1}
          flexGrow={1}
          overflow="auto"
          className={styles.content}>
          {props.children ? props.children : null}
        </Box>
      </Box>
    </>
  );
};

export default MainContentLayout;
