import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { MsalContext } from '@azure/msal-react';

import { LoginStatus } from '@auth/models/LoginStatus';
import { UserStatus } from '@auth/models/UserStatus';
import { getUser } from '@auth/AuthService';

const defaultUserStatus: UserStatus = {
  User: undefined,
  Status: LoginStatus.Loading,
};

const UserContext = React.createContext<UserStatus>(defaultUserStatus);

export const UserProvider = (props: {
  children: ReactElement;
}): ReactElement => {
  const [userStatus, setUserStatus] = useState<UserStatus>(defaultUserStatus);

  const { accounts } = useContext(MsalContext);

  useEffect(() => {
    if (accounts.length === 0) {
      setUserStatus({ User: undefined, Status: LoginStatus.Error });
      return;
    }

    getUser()
      .then((user) => {
        setUserStatus({
          User: user,
          Status: LoginStatus.LoggedIn,
        });
      })
      .catch((error) => {
        setUserStatus({ User: undefined, Status: LoginStatus.Error });
        console.log(error);
      });
  }, [accounts]);

  return (
    <UserContext.Provider value={userStatus}>
      {props.children}
    </UserContext.Provider>
  );
};

export const UserConsumer = UserContext.Consumer;

export default UserContext;
