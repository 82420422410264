export const msalConfig = {
  auth: {
    clientId: window.runConfig.azureAdAppId,
    authority: window.runConfig.azureAdAuthority,
    redirectUri: window.location.origin,
    postLogoutRedirectUri: window.location.origin,
  },
  cache: {
    cacheLocation: 'sessionStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: window.runConfig.userScopes.split(','),
};
