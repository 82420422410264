import React from 'react';
import { createRoot } from 'react-dom/client';
import { StylesProvider } from '@mui/styles';
import { ThemeProvider } from '@mui/material/styles';
import { AtriasTheme } from '@atrias/react-atrias-components';
import {
  MsalProvider,
  MsalAuthenticationTemplate,
  MsalAuthenticationResult,
} from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import { HelmetProvider } from 'react-helmet-async';

import App from './App';
import * as serviceWorker from './serviceWorker';
import { MsalInstance } from '@auth/MsalUtils';
import AtriasError from '@common/components/Layout/AtriasError';
import PageLoader from '@common/components/Layout/PageLoader';
import { UserProvider } from '@auth/UserContext';
import './index.scss';

function AuthErrorComponent(error: MsalAuthenticationResult) {
  return (
    <AtriasError
      text={
        error.error?.errorMessage
          ? 'An error occured during authentication. Please try again later.'
          : 'An error occured during authentication.'
      }
      errorMessage={error.error?.errorMessage}
    />
  );
}

const authRequest = {
  scopes: ['openid', 'profile'],
};

// Modification to AtriasTheme to get rid of unused prop warning
delete AtriasTheme.components?.MuiSelect?.defaultProps;

const app = (
  <React.StrictMode>
    <HelmetProvider>
      <ThemeProvider theme={AtriasTheme}>
        <StylesProvider injectFirst>
          <MsalProvider instance={MsalInstance}>
            <MsalAuthenticationTemplate
              interactionType={InteractionType.Redirect}
              authenticationRequest={authRequest}
              errorComponent={AuthErrorComponent}
              loadingComponent={PageLoader}>
              <UserProvider>
                <App />
              </UserProvider>
            </MsalAuthenticationTemplate>
          </MsalProvider>
        </StylesProvider>
      </ThemeProvider>
    </HelmetProvider>
  </React.StrictMode>
);

const container = document.getElementById('root');
const root = createRoot(container);
root.render(app);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
