import { Role } from '@auth/models/Role';

// eslint-disable-next-line import/prefer-default-export
export const GetRoleDescription = (role: Role): string => {
  // eslint-disable-next-line sonarjs/no-small-switch
  switch (role) {
    case Role.BalanceSupplierReader: {
      return 'Balance Supplier';
    }
    case Role.DgoReader: {
      return 'DGO';
    }
    case Role.AtriasContributor: {
      return 'Atrias Contributor';
    }
    case Role.AtriasReader: {
      return 'Atrias Reader';
    }
    default: {
      return 'Undefined';
    }
  }
};

// eslint-disable-next-line import/prefer-default-export
export const StringToRoleMapper = (role: string): Role => {
  // eslint-disable-next-line sonarjs/no-small-switch
  switch (role) {
    case 'DEX_BS_READER': {
      return Role.BalanceSupplierReader;
    }
    case 'DEX_DGO_READER': {
      return Role.DgoReader;
    }
    case 'DEX_ATR_CONTRIBUTOR': {
      return Role.AtriasContributor;
    }
    case 'DEX_ATR_READER': {
      return Role.AtriasReader;
    }
  }
};
