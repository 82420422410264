import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { Role } from '@auth/models/Role';
import * as Paths from '@routes/Paths';
import RoleGuardedRoute from '@routes/RoleGuardedRoute';
import { RouteTemplate } from './RouteTemplate';

const TemporaryLandingPage = React.lazy(
  () => import('@common/components/Layout/TemporaryLandingPage')
);
const Logout = React.lazy(() => import('@common/components/Layout/Logout'));

const NotFound = React.lazy(() => import('@common/components/Layout/NotFound'));

const Jobs = React.lazy(() => import('@JobManager/components/Jobs'));

const JobBacklog = React.lazy(
  () => import('@JobBacklog/components/JobBacklog')
);

const FileBacklog = React.lazy(
  () => import('@FileBacklog/components/FileBacklog')
);

const MeasureData = React.lazy(
  () => import('@MeasureData/components/MeasureData')
);

const Transactions = React.lazy(
  () => import('@Transactions/components/Transactions')
);

const TransactionStatistics = React.lazy(
  () => import('@Transactions/components/TransactionStatistics')
);

const RouteConfig: RouteTemplate[] = [
  {
    path: Paths.homePagePath,
    component: TemporaryLandingPage,
    navigation: {
      show: false,
    },
    redirectRoute: [
      { role: Role.AtriasContributor, redirect: Paths.jobsPath },
      { role: Role.AtriasReader, redirect: Paths.jobsPath },
      { role: Role.DgoReader, redirect: Paths.jobsPath },
      { role: Role.BalanceSupplierReader, redirect: Paths.measureDataPath },
    ],
  },
  {
    path: Paths.jobsPath,
    component: Jobs,
    navigation: {
      show: true,
      title: 'Job Manager',
    },
    roles: [Role.DgoReader, Role.AtriasContributor, Role.AtriasReader],
  },
  {
    path: Paths.jobBacklogPath,
    component: JobBacklog,
    navigation: {
      show: true,
      title: 'Job Backlog',
    },
    roles: [Role.AtriasContributor, Role.AtriasReader],
  },
  {
    path: Paths.fileBacklogPath,
    component: FileBacklog,
    navigation: {
      show: true,
      title: 'File Backlog',
    },
    roles: [Role.DgoReader, Role.AtriasContributor, Role.AtriasReader],
  },
  {
    path: Paths.measureDataPath,
    component: MeasureData,
    navigation: {
      show: true,
      title: 'Measure Data',
    },
    roles: [
      Role.BalanceSupplierReader,
      Role.DgoReader,
      Role.AtriasContributor,
      Role.AtriasReader,
    ],
  },
  {
    path: Paths.transactionsPath,
    component: Transactions,
    navigation: {
      show: true,
      title: 'Transactions',
    },
    roles: [
      Role.BalanceSupplierReader,
      Role.DgoReader,
      Role.AtriasContributor,
      Role.AtriasReader,
    ],
  },
  {
    path: Paths.transactionStatisticsPath,
    component: TransactionStatistics,
    navigation: {
      show: true,
      title: 'Transaction Statistics',
    },
    roles: [
      Role.BalanceSupplierReader,
      Role.DgoReader,
      Role.AtriasContributor,
      Role.AtriasReader,
    ],
  },
  {
    path: Paths.logoutPath,
    component: Logout,
    navigation: {
      show: false,
    },
  },
  {
    path: '*',
    component: NotFound,
    navigation: {
      show: false,
    },
  },
];

export const Router = () => {
  return (
    <Routes>
      {RouteConfig.map((route) => (
        <Route
          key={route.path}
          path={route.path}
          element={
            <RoleGuardedRoute
              roles={route.roles}
              redirectRoutes={route.redirectRoute}>
              <route.component />
            </RoleGuardedRoute>
          }
        />
      ))}
    </Routes>
  );
};

export default RouteConfig;
