import axios from 'axios';

import MsalUtils from '@auth/MsalUtils';
import { loginRequest } from '@auth/msal-config';

const currentUrl = window.location.hostname;

const backendUrl = currentUrl.startsWith('ebl')
  ? window.runConfig.eblBackendUrl
  : window.runConfig.backendUrl;

const AxiosInstance = axios.create({
  baseURL: `${backendUrl}/api/v1.0/`,
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json',
  },
  paramsSerializer: {
    indexes: null,
  },
});

AxiosInstance.interceptors.request.use(async (request) => {
  const accessToken = await MsalUtils.getAccessToken(loginRequest.scopes);

  if (accessToken) {
    request.headers.Authorization = `Bearer ${accessToken}`;
  }

  return request;
});

export default AxiosInstance;
