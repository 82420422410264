import {
  EndSessionRequest,
  InteractionRequiredAuthError,
  PublicClientApplication,
} from '@azure/msal-browser';

import { msalConfig } from '@auth/msal-config.js';

// MSAL configuration
export const MsalInstance = new PublicClientApplication(msalConfig);

export default {
  async getAccessToken(scopes: Array<string>): Promise<string | null> {
    const accounts = MsalInstance.getAllAccounts();

    if (accounts.length === 0) {
      /*
       * User is not signed in. Throw error or wait for user to login.
       * Do not attempt to log a user in outside of the context of MsalProvider
       */
      return null;
    }

    const request = {
      scopes,
      account: accounts[0],
    };

    const authResult = await MsalInstance.acquireTokenSilent(request).catch(
      (error) => {
        if (error instanceof InteractionRequiredAuthError) {
          return MsalInstance.acquireTokenRedirect(request);
        }
      }
    );

    if (!authResult) {
      // see https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/4104
      console.warn(
        'Auth result was null. This could mean the refresh token has expired, or the access token was revoked. Logging user out.'
      );

      await this.logout();
      return null;
    }

    return authResult.accessToken;
  },

  async logout(): Promise<void> {
    const request: EndSessionRequest = {
      postLogoutRedirectUri: msalConfig.auth.postLogoutRedirectUri,
    };

    const accounts = MsalInstance.getAllAccounts();
    if (accounts.length !== 0) {
      request.account = accounts[0];
    }

    await MsalInstance.logoutRedirect(request);
  },
};
