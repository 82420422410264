import React, { ReactElement, useContext } from 'react';
import { Link, NavLink } from 'react-router-dom';

import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';
import PowerSettingsNewRoundedIcon from '@mui/icons-material/PowerSettingsNewRounded';
import { Grid, IconButton, Tooltip, Typography } from '@mui/material';

import AtriasLogo from '@assets/images/atrias-logo.svg';
import { LoginStatus } from '@auth/models/LoginStatus';
import { Role } from '@auth/models/Role';
import { UserStatus } from '@auth/models/UserStatus';
import UserContext from '@auth/UserContext';
import RoleGuardedComponent from '@common/components/RoleGuardedComponent';
import { GetRoleDescription } from '@common/mappers/RoleMapper';
import { homePagePath, logoutPath } from '@routes/Paths';
import RouteConfig from '@routes/RouteConfig';
import { RouteTemplate } from '@routes/RouteTemplate';
import styles from './style.module.scss';

const SidebarContent = (): ReactElement => {
  const currentUserStatus: UserStatus = useContext(UserContext);

  const questions = (
    <Grid container className={styles.questions} direction="column">
      Any questions?
      <Link
        to="https://atrias.topdesk.net/"
        target="_blank"
        rel="noopener"
        className={styles.topDeskLink}>
        <Grid
          container
          direction="row"
          spacing={1}
          alignItems="flex-end"
          wrap="nowrap">
          <Grid item>Contact Topdesk</Grid>
          <Grid item className={styles.forwardIcon}>
            <ArrowForwardRoundedIcon fontSize="small" />
          </Grid>
        </Grid>
      </Link>
    </Grid>
  );

  let roles = <span>No roles</span>;
  if (
    currentUserStatus.Status === LoginStatus.LoggedIn &&
    currentUserStatus.User.roles.length !== 0
  ) {
    roles =
      currentUserStatus.User.roles.length === 1 ? (
        <span>{GetRoleDescription(currentUserStatus.User.roles[0])}</span>
      ) : (
        <Tooltip
          title={currentUserStatus.User.roles
            .map((role: Role) => GetRoleDescription(role))
            .join(', ')}
          placement="right">
          <span>
            {GetRoleDescription(currentUserStatus.User.roles[0])} +{' '}
            {currentUserStatus.User?.roles.length - 1}
          </span>
        </Tooltip>
      );
  }

  const userInfo = (
    <Grid
      container
      className={styles.userInfo}
      direction="row"
      wrap="nowrap"
      alignItems="center">
      <Grid item container direction="column" zeroMinWidth wrap="nowrap">
        <Grid item zeroMinWidth>
          <Typography variant="body2" noWrap>
            <Tooltip
              title={currentUserStatus.User?.fullName ?? ''}
              placement="right">
              <strong>{currentUserStatus.User?.fullName}</strong>
            </Tooltip>
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="subtitle2" className={styles.role}>
            {roles}
          </Typography>
        </Grid>
      </Grid>
      <Grid item>
        <Tooltip title="Log out" placement="right">
          <IconButton
            component={Link}
            to={logoutPath}
            className={styles.logOutButton}
            aria-label="Log out">
            <PowerSettingsNewRoundedIcon />
          </IconButton>
        </Tooltip>
      </Grid>
    </Grid>
  );

  const navigationRowItem = (route: RouteTemplate) => (
    <RoleGuardedComponent visibleForRoles={route.roles}>
      <NavLink
        className={({ isActive }) =>
          isActive ? styles.navRowItemActive : styles.navRowItem
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        to={route.path}
        color="inherit">
        {route.navigation.title ?? 'No name'}
      </NavLink>
    </RoleGuardedComponent>
  );

  return (
    <Grid
      container
      className={styles.sidebarContent}
      direction="column"
      justifyContent="space-between"
      alignItems="center">
      <Grid item container direction="column" alignItems="center">
        <Grid item>
          <NavLink to={homePagePath}>
            <img
              className={styles.atriasLogo}
              src={AtriasLogo}
              alt="Atrias Logo"
            />
          </NavLink>
        </Grid>
        <Grid item className={styles.links} container direction="column">
          {RouteConfig.map((route) => (
            <React.Fragment key={route.path}>
              {route.navigation.show && navigationRowItem(route)}
            </React.Fragment>
          ))}
        </Grid>
      </Grid>
      <Grid item className={styles.bottomItems} container direction="column">
        <RoleGuardedComponent visibleForRoles={[Role.AtriasContributor]}>
          {questions}
        </RoleGuardedComponent>
        {userInfo}
      </Grid>
    </Grid>
  );
};

export default SidebarContent;
