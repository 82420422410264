import React, { ReactElement, Suspense } from 'react';
import { Helmet } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import BaseLayout from '@common/components/Layout/BaseLayout';
import MainContentLayout from '@common/components/Layout/BaseLayout/MainContentLayout';
import { Router } from '@routes/RouteConfig';

declare global {
  interface Window {
    runConfig: {
      version: string;
      environment: string;
      backendUrl: string;
      eblBackendUrl: string;
      showVersionBanner: string;
    };
  }
}

const App = (): ReactElement => {
  return (
    <>
      <Helmet>
        <title>Atrias DEX</title>
        <meta
          name="description"
          content="Atrias provides support for the development of the liberalised Belgian energy market on the distribution level,
          playing a key role as an information provider."
        />
      </Helmet>
      <BrowserRouter>
        <BaseLayout>
          <Suspense fallback={<MainContentLayout loading pageTitle="" />}>
            <Router />
          </Suspense>
        </BaseLayout>
      </BrowserRouter>
    </>
  );
};

export default App;
