import React, { useContext } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { PageLoader } from '@atrias/react-atrias-components';

import UserContext from '@auth/UserContext';
import AtriasError from '@common/components/Layout/AtriasError';
import { Role } from '@auth/models/Role';
import { LoginStatus } from '@auth/models/LoginStatus';
import { UserStatus } from '@auth/models/UserStatus';

const RoleGuardedRoute = ({
  children,
  redirectRoutes,
  roles,
}: {
  children: JSX.Element;
  redirectRoutes: { role: Role; redirect: string }[];
  roles: Array<Role>;
}) => {
  const location = useLocation();
  const currentUserStatus: UserStatus = useContext(UserContext);

  if (redirectRoutes && currentUserStatus.Status === LoginStatus.LoggedIn) {
    const route = redirectRoutes.find(
      (redirectRoute) => redirectRoute.role === currentUserStatus.User?.roles[0]
    ).redirect;

    return <Navigate to={route} />;
  }

  if (!roles || !roles.length) {
    return children;
  }

  if (
    currentUserStatus.Status === LoginStatus.LoggedIn &&
    currentUserStatus.User.isAuthorizedFor(roles)
  ) {
    return children;
  }

  if (currentUserStatus.Status === LoginStatus.Loading) {
    return <PageLoader></PageLoader>;
  }

  if (currentUserStatus.Status === LoginStatus.Error) {
    return <AtriasError text="There was an error during login"></AtriasError>;
  }

  return <Navigate to="/" state={{ from: location }} />;
};

export default RoleGuardedRoute;
