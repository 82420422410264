import AxiosInstance from '@common/AxiosInstance';
import { User, FilterOptions } from './models/User';

export const getUser = (): Promise<User> => {
  return AxiosInstance.get<BackendUser>(`/auth/user`).then((response) => {
    return new User(
      response.data.id,
      response.data.fullName,
      response.data.partyName,
      response.data.Roles,
      response.data.atriasParties,
      response.data.dgoParties,
      response.data.sources,
      response.data.glns,
      response.data.filterOptions
    );
  });
};

// Typescript is unable to link the Roles property from backend to roles property of frontend
// (uppercase R vs lowercase r)
class BackendUser {
  id: string;

  fullName: string;

  partyName: string;

  Roles: string[];

  atriasParties: string[];

  dgoParties: string[];

  sources: string[];

  glns: string[];

  filterOptions: FilterOptions;
}
